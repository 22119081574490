<template>
  <b-card>

    <!-- media -->
    <!-- <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="userData.profile.avatar"
            height="80"
          />
          <b-img
            v-if="!userData.profile.avatar"
            ref="previewEl"
            rounded
            :src="require('@/assets/images/illustration/no_image.png')"
            height="80"
          />
        </b-link> -->
    <!--/ avatar -->
    <!-- </b-media-aside>

      <b-media-body class="mt-75 ml-75"> -->
    <!-- upload button -->
    <!-- <b-form-file
          ref="refInputEl"
          accept="image/*"
          @change="onUploadImage"
        /> -->
    <!--/ upload button -->

    <!-- <b-card-text>Allowed JPG or PNG. Max size of 500kB</b-card-text>
      </b-media-body>
    </b-media> -->
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="12">
          <b-form-group
            label="Name"
            label-for="account-name"
          >
            <b-form-input
              v-model="userData.profile.name"
              name="name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="userData.profile.email"
              name="email"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group
            label="No. Handphone"
            label-for="account-phone"
          >
            <b-form-input
              v-model="userData.profile.phone"
              name="phone"
            />

          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            :disabled="loading"
            @click="postProfile"
          >
            Simpan Perubahan
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  computed, defineComponent, onMounted, reactive, ref,
} from '@vue/composition-api'
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default defineComponent({
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  setup(_, { root }) {
    const toast = root.$toast
    const loading = ref(false)
    const getUserProfile = () => {
      store.dispatch('user/getUserProfile')
    }

    onMounted(() => {
      getUserProfile()
    })

    const userData = reactive({
      profile: computed(() => store.state.user.user),
    })

    // const form = reactive({
    //   name: '',
    //   email: '',
    //   phone: '',
    //   avatar: '',
    // })

    const loadProfile = () => {
      const { user } = store.state.user

      userData.profile.name = user.name
      userData.profile.email = user.email
      userData.profile.phone = user.phone
      // userData.profile.avatar = user.avatar
    }

    const onUploadImage = () => {
      const file = document.querySelector('input[type="file').files[0]

      if (file) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Notifikasi',
            text: 'Mengunggah gambar...',
            icon: 'BellIcon',
          },
        },
        {
          timeout: 1500,
        })

        // create temporary formdata
        const formData = new FormData()
        formData.append('image', file)

        // upload image
        store.dispatch('user/postPicture', { file: formData }).then(() => {
          // const { picture } = store.state.user.user
          // userData.profile.avatar = picture

          toast({
            component: ToastificationContent,
            props: {
              title: 'Notifikasi',
              text: 'Gambar berhasil diunggah',
              icon: 'BellIcon',
            },
          })
        })
      }
    }

    const postProfile = () => {
      loading.value = true
      const payload = {
        name: userData.profile.name,
        phone: userData.profile.phone,
        // avatar: userData.profile.avatar,
      }
      store.dispatch('user/editUserProfile', payload)
      toast({
        component: ToastificationContent,
        props: {
          title: 'Notifikasi',
          text: 'User Berhasil di Update',
          icon: 'BellIcon',
        },
      })
      // const user = JSON.parse(localStorage.getItem('userData'))
      // user.avatar = userData.profile.avatar
      // user.fullname = userData.profile.name
      // localStorage.setItem('userData', JSON.stringify(user))
      loading.value = false
      // window.location.reload()
    }

    return {
      userData,
      getUserProfile,
      // userData,
      loadProfile,
      onUploadImage,
      postProfile,
      loading,
    }
  },
})
</script>
