<template>
  <div>
    <!-- <AlertChange v-if="passwordExpired" /> -->
    <b-card>
      <!-- form -->
      <b-form>
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              label="Password Lama"
              label-for="account-old-password"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="account-old-password"
                  v-model="passwordValueOld"
                  name="old-password"
                  :type="passwordFieldTypeOld"
                  placeholder="Password Lama"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconOld"
                    class="cursor-pointer"
                    @click="togglePasswordOld"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              label="Password Baru"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="account-new-password"
                  v-model="newPasswordValue"
                  :type="passwordFieldTypeNew"
                  name="new-password"
                  placeholder="Password Baru"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconNew"
                    class="cursor-pointer"
                    @click="togglePasswordNew"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              label="Ulangi Password Baru"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="account-retype-new-password"
                  v-model="RetypePassword"
                  :type="passwordFieldTypeRetype"
                  name="retype-password"
                  placeholder="Ulangi Password Baru"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconRetype"
                    class="cursor-pointer"
                    @click="togglePasswordRetype"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click="changePassword"
            >
              Ganti Password
            </b-button>
          </b-col>
        <!--/ buttons -->
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  defineComponent, ref, computed, onMounted,
} from '@vue/composition-api'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
// import AlertChange from '@/views/pages/account-setting/AlertChangePassword.vue'

export default defineComponent({
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    // AlertChange,
  },
  directives: {
    Ripple,
  },
  setup() {
    const passwordValueOld = ref('')
    const newPasswordValue = ref('')
    const RetypePassword = ref('')
    const passwordFieldTypeOld = ref('password')
    const passwordFieldTypeNew = ref('password')
    const passwordFieldTypeRetype = ref('password')

    const passwordToggleIconOld = computed(() => (passwordFieldTypeOld.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'))
    const passwordToggleIconNew = computed(() => (passwordFieldTypeNew.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'))
    const passwordToggleIconRetype = computed(() => (passwordFieldTypeRetype.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'))

    const passwordExpired = ref(false)

    const togglePasswordOld = () => {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    }
    const togglePasswordNew = () => {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    }
    const togglePasswordRetype = () => {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    }

    const changePassword = () => {
      if (newPasswordValue.value !== RetypePassword.value) {
        Swal.fire({
          title: 'Gagal',
          text: 'Retype Password tidak cocok',
          icon: 'error',
          confirmButtonText: 'OK',
        })
      } else if (newPasswordValue.value === RetypePassword.value) {
        const payload = {
          password_new: newPasswordValue.value,
          password_old: passwordValueOld.value,
        }
        store.dispatch('user/editSellerProfile', payload)
      }
    }

    onMounted(() => {
      passwordExpired.value = true
    })

    return {
      passwordValueOld,
      newPasswordValue,
      RetypePassword,
      passwordFieldTypeOld,
      passwordFieldTypeNew,
      passwordFieldTypeRetype,
      passwordToggleIconOld,
      passwordToggleIconNew,
      passwordToggleIconRetype,
      togglePasswordOld,
      togglePasswordNew,
      togglePasswordRetype,
      changePassword,
      passwordExpired,
    }
  },
})
</script>
